import * as React from 'react';
import { forwardRef } from 'react';
import { AppBar, UserMenu, MenuItemLink } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core/styles';
import {colors} from '../themes'
import Logo from './Logo';
import { useSelector } from "react-redux";
import ExitIcon from '@material-ui/icons/PowerSettingsNew';
import {Logout} from '../utility'
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});

const MyLogoutButton = forwardRef((props, ref) => {
    const _kc = useSelector(p => p.app._kc)
    const logout = () => Logout(_kc)
    return (
        <MenuItemLink
            ref={ref}
            to="/"
            primaryText={"Logout"}
            leftIcon={<ExitIcon />}
            onClick={logout}
            sidebarIsOpen
        />
    );
});
const ConfigurationMenu = forwardRef((props, ref) => {
    return (
        <MenuItemLink
            ref={ref}
            to="/profile"
            primaryText={"Profile"}
            leftIcon={<SettingsIcon />}
            onClick={props.onClick}
            sidebarIsOpen
        />
    );
});

const CustomUserMenu = (props) => (
    <UserMenu {...props}>
        <ConfigurationMenu /> 
        <MyLogoutButton /> 
    </UserMenu>
);

const CustomAppBar = (props) => {
    const classes = useStyles();
    const reload = useSelector(p =>p.app.reload)
    return (
        <AppBar elevation={1} userMenu={<CustomUserMenu />} style={{backgroundColor: colors.secondary, color: colors.primary}} >
             {/* <Logo /> */}
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
                />
                {reload && <CircularProgress size={20} />}
            {/* <Logo /> */}
            <span className={classes.spacer} />
        </AppBar>
    );
};

export default CustomAppBar;
