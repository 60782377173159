import * as React from 'react';
import { useSelector } from 'react-redux';
import { Layout, LayoutProps, MenuItemLink, Responsive } from 'react-admin';
import AppBar from './AppBar';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import HelpIcon from '@mui/icons-material/Help';
import PeopleIcon from '@mui/icons-material/People';
import ArticleIcon from '@mui/icons-material/Article';
import Logo from './Logo'
import { refleshDATASET, getContract, getTransaction, countTransactionUser} from '../utility'

const MyMenu = ({ onMenuClick, logout, dense=false }) => {
    const [state, setState] = React.useState({
        menuCatalog: true,
        menuSales: true,
        menuCustomers: true,
    });
    const open = useSelector((state) => state.admin.ui.sidebarOpen);
    const show_admin_menu = useSelector((state) => state.app.show_admin_menu);
    const is_admin = useSelector((state) => state.app.is_admin);
    useSelector((state) => state.theme); // force rerender on theme change
    const classes = useStyles();

 

    return(
        <div
            className={classnames(classes.root, {
                [classes.open]: open,
                [classes.closed]: !open,
            })}
        >
            <div style={{alignContent: "center", alignItems: "center", justifyContent: "center", padding: '5px'}}> 
            
            </div>
            <MenuItemLink
                to={{
                    pathname: "/",
                    state: { _scrollToTop: true },
                }}
                primaryText={"Dashboard"}
                leftIcon={<DashboardIcon />}
                sidebarIsOpen
                dense={dense}
                onClick={()=>{refleshDATASET(); getContract()}}
            />
            <MenuItemLink
                to={{
                    pathname: "/transactions",
                    state: { _scrollToTop: true },
                }} 
                primaryText={"Transactions"}
                leftIcon={<FormatListNumberedRtlIcon />}
                sidebarIsOpen
                onClick={()=>{getTransaction(); countTransactionUser()}}
            />
            <MenuItemLink
                to={{
                    pathname: "/help",
                    state: { _scrollToTop: true },
                }}
                primaryText={"Help"}
                leftIcon={<HelpIcon />}
                sidebarIsOpen
            />
            {show_admin_menu&&
                <div>
                    <div style={{paddingTop: "10px", paddingBottom: "10px"}}>
                        <span>ADMIN</span>
                    </div>
                    <MenuItemLink
                        to={"/admin/users"}
                        primaryText={"Users"}
                        leftIcon={<PeopleIcon />}
                    />
                    <MenuItemLink
                        to={"/admin/transactions"}
                        primaryText={"Transactions"}
                        leftIcon={<FormatListNumberedRtlIcon />}
                    />
                    <MenuItemLink
                        to={"/admin/contracts"}
                        primaryText={"Contracts"}
                        leftIcon={<ArticleIcon />}
                    />
                    {is_admin &&
                        <MenuItemLink
                            to={"/admin/mainAccount"}
                            primaryText={"Main Account"}
                            leftIcon={<ArticleIcon />}
                        />
                    }
                </div>
            }
        </div>
    )
  }


export default (props) => {
    return <Layout {...props} appBar={AppBar}  menu={MyMenu} />;
};


const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    open: {
        width: 200,
    },
    closed: {
        width: 55,
    },
}));

 

