import * as React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {getMainAccountInfos, onProcessMainRechargeRIA, verificationOTP} from '../../utility'
import { Title } from 'react-admin';
import Button from '@mui/material/Button';
import Snack from '../../components/Snack'
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

export function MainAccount(){
    const [ria_balance, setRiaBal] = React.useState(null)
    const [form, showForm] = React.useState(false)
    const [otp, showOtp] = React.useState(false)
    const [new_recharge_amount, setR] = React.useState(null)
    const [otpCode, setOtpCode] = React.useState(null)
    const [objectId, setObjectID] = React.useState(false)
    const [openSnack, setOpenSnack] = React.useState(false)
    const [messageSkack, setMessageSnack] = React.useState("")
    const [type, setTypeSnack] = React.useState("error")
    const [reason, setReason] = React.useState("")

    const onConfirm = async ()=>{
        setOpenSnack(false)
        if(!otpCode||otpCode&&otpCode.trim() === ""){
            setMessageSnack('OTP is required ')
            setOpenSnack(true)
            return
        }
        const res = await verificationOTP({new_recharge_amount, objectId, otpCode})
        console.log('result result result', res)
        if(res && !res.error){
            fetchDAT()
            showOtp(false)
            showForm(false)
            setOtpCode(null)
            setR(null)
            setTypeSnack('success')
            setMessageSnack(res.message)
            setOpenSnack(true)
        }else{
            setTypeSnack('error')
            setMessageSnack(res.message || 'Unknow error !')
            setOpenSnack(true)
        }
    }
    const onProcess = async ()=>{
        setOpenSnack(false)
        if(!new_recharge_amount || new_recharge_amount <0){
            setTypeSnack('error')
            setMessageSnack('Invalid amount !')
            setOpenSnack(true)
            return
        }
        if(!reason || reason&&reason.trim() ===""){
            setTypeSnack('error')
            setMessageSnack('Reason not provided !')
            setOpenSnack(true)
            return
        }
        const res = await onProcessMainRechargeRIA(new_recharge_amount, reason)
        if(res && !res.error){
            showOtp(true)
            setObjectID(res.id)
        }else{
            setTypeSnack('error')
            setMessageSnack(res.message || 'Unknow error !')
            setOpenSnack(true)
        }
        console.log('res res res res res', res)
    }
    function fetchDAT(){
        (async ()=>{
            const mainAcc = await getMainAccountInfos()
            setRiaBal(mainAcc?.data)
            console.log('count count count count', mainAcc)
        })()
    }
    React.useEffect(()=>{ 
        fetchDAT()
    }, [])

    return(
        <div>
            <Card>
                <CardContent>
                    <h4>RIA Balance:{" "+(ria_balance? ria_balance: "")}</h4>
                    
                    {!form &&
                        <Button variant="outlined" onClick={()=>showForm(true)}>New Release</Button>
                    }
                    {form &&
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item md={6}  xs={12}>  
                                    <TextField  
                                        label="Release value" 
                                        type="number" 
                                        disabled={otp} 
                                        value={new_recharge_amount} 
                                        onChange={(e)=>setR(e.target.value)} 
                                        variant="outlined" 
                                        fullWidth
                                    />
                                    <div style={{height: '20px'}}/>
                                    <TextField  
                                        label="Reason of release" 
                                        disabled={otp} 
                                        multiline
                                        fullWidth
                                        value={reason} 
                                        aria-label="minimum height"
                                        minRows={3}
                                        onChange={(e)=>setReason(e.target.value)} variant="outlined" 
                                    />
                                    <div style={{height: '20px'}}/>
                                    
                                    {otp && <TextField type="text" label="Otp code" value={otpCode} onChange={(e)=>setOtpCode(e.target.value)} variant="outlined"/>}
                                    <div style={{height: '20px'}}/>
                                    {!otp &&<Button variant="outlined" onClick={()=>onProcess(true)}>Process</Button>}
                                    {otp &&<Button variant="outlined" onClick={()=>onConfirm()}>Confirm</Button>}
                                </Grid>
                            </Grid>
                        </Box>
                    }
                </CardContent>
            </Card>
            <Snack open={openSnack} message={messageSkack} muteSnack={setOpenSnack} type={type}/>
        </div>
    )
}