var Parse = require('parse');

//init of variables
const PARSE_SERVER_URL1 = "https://backend.mctibv.com/parse";

const KEYCLOAK_AUTH_SERVER_URL = "https://sso.mctibv.com/auth";
const PARSE_SERVER_APP_ID1 = "com.mctibv.backend"

const KEYCLOAK_REALM = "mcti_prod";

const ENV = "prod";

export const WEBSITE_URL = "https://www.mctibv.com";
export const UPDATE_INFOS_PATH="/update-infos"
export const INVIATION_SHORT_LINK="/invitation-link"
const CUSTOMER_PORTAL_URL = "https://cp.mctibv.com";

export const PARSE_SERVER_APP_ID = PARSE_SERVER_APP_ID1
export const PARSE_SERVER_URL = PARSE_SERVER_URL1


export const config = {
    "realm": KEYCLOAK_REALM,
    "auth-server-url": KEYCLOAK_AUTH_SERVER_URL,
    "ssl-required": "external",
    "resource": "mcti-frontend",
    "clientId": "mcti-frontend",
    "public-client": true,
    "verify-token-audience": true,
    "use-resource-role-mappings": true,
    "confidential-port": 0
}

Parse.initialize(PARSE_SERVER_APP_ID1);
Parse.serverURL = PARSE_SERVER_URL1 ;

export default Parse


if(ENV === "prod")
{
    console.log = function(){}; 
}