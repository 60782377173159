// in src/Dashboard.js
import * as React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';
import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { CreateButton } from 'react-admin';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import {colors} from '../themes'
import DialogTopUp from "../components/DialogTopUp";
import DialogTransfert from "../components/DialogTransfert";
import PackRIA from "../components/PackRIA";
import InviteFriend from '../components/InviteFriend';
import Typography from '@material-ui/core/Typography';
import { useSelector } from "react-redux";
import ContractTable from '../components/ContractTable'
import { useDispatch } from "react-redux";
import { refleshDATASET } from '../utility'
import Logo from '../layout/Logo';


const CARD = ({title, value, subvalue, title2}) => {
    return(
        <Card style={styles.card}>
            <CardContent>
                {title}
                <p style={{fontWeight: "bold", fontSize: 20}}>{value || 0}</p>
                {title2 && <>
                    {title2}
                    <p style={{fontWeight: "bold", fontSize: 17}}>{subvalue || 0}</p>
                </>}
            </CardContent>
        </Card>
    )
}
const subDashLabel = [

]
export default (props) => {
    const [openDialogPopUp, setOpenDialogPopUp] = React.useState(false);
    const [openPackRIA, setOpenPackRIA] = React.useState(false);
    const [openTransfert, setOpenTransfert] = React.useState(false);
    const [openInviteFriend, setOpenInviteFriend] = React.useState(false);
    const currentUser = useSelector(p => p.app?.currentUser)
    const contacts = useSelector(p => p.app?.contacts)

    if((window.location.href).split('/#/')[1]){
        props.history.push('/')

    }

    const setOpenDialogPopUp1 =async (arg1, arg2) => {
        setOpenDialogPopUp(arg1)
        console.log('refleshDATASET before')
        if(arg2){
            await refleshDATASET()
        }
    }
    const setOpenTransfert1 =async (arg1, arg2) => {
        setOpenTransfert(arg1)
        if(arg2){
            await refleshDATASET()
        }
    }
    const setOpenPackRIA1 =async (arg1, arg2) => {
        setOpenPackRIA(arg1)
        console.log('refleshDATASET before')
        if(arg2){
            await refleshDATASET()
        }
    }
    

    return(
        <>
            <Title title={<Logo title="DASHBOARD"/>} />
            <Box sx={{ flexGrow: 1 }} style={{alignItems: "center", justifyContent: 'center'}}>
                <Grid container spacing={2}>
                    <Grid item md={3}  xs={12}>
                        <CARD title2="Free Balance" title="Balance" value={currentUser?.userSecure?.balance} subvalue={currentUser?.userSecure?.balance_free}/>
                    </Grid>
                    <Grid item md={3}  xs={12}>
                        <CARD title2="Payout turnover" title="Payout Balance" value={currentUser?.userSecure?.payout_balance} subvalue={currentUser?.userSecure?.payout_turnover}/>
                    </Grid>
                    <Grid item md={3}  xs={12}>
                        <CARD title2="RECO Turnover" title="RECO Balance" value={currentUser?.userSecure?.reco_balance} subvalue={currentUser?.userSecure?.reco_turnover}/>
                    </Grid>
                    <Grid item md={3}  xs={12}>
                        <CARD title2="Active investment" title="Investment Turnover" value={currentUser?.userSecure?.investment_turnover} subvalue={currentUser?.userSecure?.active_investment}/>
                    </Grid>

                    <Grid item md={12} xs={12}>
                        <Divider/>
                    </Grid>

                    <Grid item md={3}  xs={12}>
                        <CARD title="Commissions" value={currentUser?.userSecure?.commissions}/>
                    </Grid>
                    <Grid item md={3}  xs={12}>
                        <CARD title="Bonus" value={currentUser?.userSecure?.bonus}/>
                    </Grid>
                    <Grid item md={3}  xs={12}>
                        <CARD title="Personal Turnover" value={currentUser?.userSecure?.personal_turnover}/>
                    </Grid>
                    <Grid item md={3}  xs={12}>
                        <CARD title="Team Turnover" value={currentUser?.userSecure?.team_turnover}/>
                    </Grid>

                    <Grid item md={12} xs={12}>
                        <Divider/>
                    </Grid>

                    {/* <Grid item md={1} xs={1}></Grid> */}
                    <Grid item md={2} xs={6}>
                        <Button 
                            variant="contained" 
                            style={{backgroundColor: colors.primary, color: 'white', ...styles.customButton}}
                            onClick={()=>setOpenDialogPopUp(!openDialogPopUp)}
                        >
                            Top-up
                        </Button>
                       
                    </Grid>
                    <Grid item md={2} xs={6}>
                        <Button 
                            variant="contained" 
                            style={{backgroundColor: colors.primary, color: 'white', ...styles.customButton}}
                            onClick={()=>setOpenPackRIA(!openPackRIA)}
                        >
                            Start investment
                        </Button>
                    </Grid>
                    <Grid item md={2} xs={6}>
                        <Button
                            variant="contained" 
                            style={{backgroundColor: colors.primary, color: 'white', ...styles.customButton}}
                            onClick={()=>setOpenTransfert(!openTransfert)}
                        >
                            Transfer
                        </Button>
                    </Grid>
                    <Grid item md={2} xs={6}>
                        <Button disabled variant="contained" style={{backgroundColor: "#AC83DC", color: 'white', ...styles.customButton}}>Cash-out</Button>
                    </Grid>
                    <Grid item md={2} xs={6}>
                        <Button 
                            variant="contained" 
                            style={{backgroundColor: colors.primary, color: 'white', ...styles.customButton}}
                            onClick={()=>setOpenInviteFriend(!openInviteFriend)}
                        >
                            Invite Friends 
                        </Button>
                    </Grid>

                    <Grid item md={12}>
                        <Divider/>
                    </Grid>

                    <Grid item md={12} xs={12}>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Card>
                            <CardContent>
                                <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div" style={{color: colors.primary, textAlign: "center"}}>
                                    {"Investment"}
                                </Typography> 
                                <ContractTable contracts={contacts}/>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            <DialogTopUp onChangeState={(_v, roload) => setOpenDialogPopUp1(_v, roload)} open={openDialogPopUp} />
            <DialogTransfert onChangeState={(_v, roload) => setOpenTransfert1(_v, roload)} open={openTransfert} />
            <PackRIA onChangeState={(_v, roload) => setOpenPackRIA1(_v, roload)} open={openPackRIA} />
            <InviteFriend onChangeState={(_v) => setOpenInviteFriend(_v)} open={openInviteFriend} />
        </>  
    )
}




const styles = {
    card: {
        backgroundColor: colors.secondary, 
        textAlign: 'center',
        // width: "200px"
        
    },
    button: {
        textDecoration: 'none'
    },
    customButton: {
        height: "50px",
        width: "100%"
    }
}