import React from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import {colors, stylesMain} from '../themes'
import Alert from '@mui/material/Alert';
import {buyRIA, WAIT} from '../utility'
import PaymentMethod from './PaymentMethod'
import CircularProgress from '@mui/material/CircularProgress';
import { WEBSITE_URL } from '../config';

const labelCheck = { inputProps: { 'aria-label': 'Switch demo' } };

export default function DialogTopUp(props){ 

  const [acceptCondition, setAccept] = React.useState(false)
  const [riaAmount, setAmountRia] = React.useState(0)
  const [loading, setLoanding] = React.useState(false)
  const [flashType, setFlasType] = React.useState("success")
  const [message, setFlasMessage] = React.useState("")
  const [showFlass, setShowFlash] = React.useState(false)
  const [showlik, setShowLink] = React.useState(false)
  const [payment, setPayment] = React.useState(null)

  const handleClickOpen = () => {
    props.onChangeState(true)
  };
  const handleCheckbox = async (event, isChecked) => {
    setAccept(isChecked)
    console.log('event, isChecked, value', event, isChecked)

  }
  const buyRIALoc = async () => {
      setShowLink(false)
      setLoanding(true)
      console.log('props.pack props.pack props.pack', props.pack)
      if(!payment){
        setFlasType("error")
        setLoanding(false)
        setShowFlash(true)
        setFlasMessage("You must select payment method !")
        return 
      }
      if(!acceptCondition){
        setFlasType("error")
        setLoanding(false)
        setShowFlash(true)
        setFlasMessage("You must acept terms and conditions !")
        return 
      }
      if(!riaAmount || riaAmount < 0){
        setFlasType("error")
        setLoanding(false)
        setShowFlash(true)
        setFlasMessage("The RIA amount is invalid. Please submit a valid value.")
        return 
      }
      const o = {amount: riaAmount, paymentMethod: payment}
      const res = await buyRIA(o)
      console.log('res res res res', res)
      if(res && res.error){
        setFlasType("error")
        if(res && res.message&&res.message?.includes('account')){
          setShowLink(true)
        }
      }else{
        setFlasType("success")
        WAIT({function: closeAndReload});
      }
      setFlasMessage(res&&res.message || "Undefined message")
      setLoanding(false)
      setShowFlash(true)
      setAmountRia(0)
      
  };
  const closeAndReload = () => {
    console.log('after time out');
    handleClose(true)
   }
  const setPaymentMethod = (e) => {
    setPayment(e)
  }

  const handleClose = (reload) => {
    setShowFlash(false)
    setLoanding(false)
    setAccept(false)
    setAmountRia(0)
    props.onChangeState(false, reload)
  };
  const handleChange = (e) => {
    setAmountRia(e.target.value)
  };

  return (
    <div>
      <Dialog open={props.open} onClose={handleClose}>
        <DialogTitle>Top Up</DialogTitle>
        <DialogContent>
          <DialogContentText>
          {showFlass&&
            <Alert severity={flashType}>
            {message} {showlik&& <a href={WEBSITE_URL+"/update-infos"} target="_blank">Verify</a> }
            </Alert>
          }
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Amount of RIA"
            type="number"
            fullWidth
            onChange={handleChange}
            value={riaAmount}
            variant="outlined"
          />
          <PaymentMethod setPaymentMethodProps={setPaymentMethod}/>
          <Typography component="div" variant="h5" style={{fontSize: stylesMain.fontSize}}>                            
              <Checkbox onChange={handleCheckbox} {...labelCheck} style={{color: colors.primary}} />
              <a href={WEBSITE_URL+"/privacy-policy"} target="_blank">
                Accept the Terms & Conditions
              </a>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={buyRIALoc} disabled={loading}>
          {loading && <CircularProgress size={20} />}
            Buy RIA
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}