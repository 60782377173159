import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import {colors, stylesMain} from '../themes'


const labelCheck = { inputProps: { 'aria-label': 'Switch demo' } };

export default function CommentPopup({row, label, onChangeStatus, textarea, trans}) {
  const [open, setOpen] = React.useState(false);
  const [comment, setComment] = React.useState("");
  const [sendMail, setSendMail] = React.useState(true)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handeChange = () => {
    setOpen(false);
    onChangeStatus(row, comment, sendMail, label)
    setSendMail(true)
    setComment("")
  };

  const handleCheckbox = async (event, isChecked) => {
    setSendMail(isChecked)
    console.log('event, isChecked, value', event, isChecked)

  }

  return (
    <div>
      <Button 
       onClick={handleClickOpen}
       style={!trans ?{backgroundColor: colors.primary, color: 'white', ...styles.customButton} : null}
      >
        {label}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle> {textarea}</DialogTitle> 
        <DialogContent>
          <TextareaAutosize
            aria-label="minimum height"
            minRows={3}
            placeholder="Comment"
            style={{ width: "100%" }}
            autoFocus
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            margin="dense"
            fullWidth
            variant="standard"
          />
          <Typography component="div" variant="h5" style={{fontSize: stylesMain.fontSize}}>                            
              <Checkbox onChange={handleCheckbox} {...labelCheck} checked={sendMail} />
              Email notification
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handeChange}>{label==="Cancel"? "Ok" : label}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const styles = {
  card: {
      backgroundColor: colors.secondary, 
      textAlign: 'center',
      // width: "200px"
      
  },
  button: {
      textDecoration: 'none'
  },
  customButton: {
    fontSize: "12px",
      height: "40px",
      width: "80%"
  }
}