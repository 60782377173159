import * as React from 'react';
import { Route, Redirect  } from 'react-router-dom';
import Profile from './screens/profile/Profile';
import Dashboard from './screens/Dashboard';
import Transaction from './screens/transaction';
import Help from './screens/help';
import {Users} from './screens/admin/Users'
import {Contract} from './screens/admin/Contrat'
import {Transactions} from './screens/admin/Transactions'
import {MainAccount} from './screens/admin/MainAccount'
import { useSelector } from 'react-redux';

export default [
            <Route exact path="/profile" render={() =><Profile />}/>,
            <Route exact path="/help" render={() => <Help />} />,
            <Route exact path="/transactions" render={() => <Transaction />} />,

            <AUTH_Routes path="/admin/users" component={Users} />,
            <AUTH_Routes path="/admin/contracts" component={Contract} />,
            <AUTH_Routes path="/admin/transactions" component={Transactions} />,
            <ADMIN_Routes path="/admin/mainAccount" component={MainAccount} />,
            
            <Route path='/' component={Dashboard} />,
        ]

function AUTH_Routes({component, path}){
    const show_admin_menu = useSelector(p => p.app.show_admin_menu)
    if(show_admin_menu){
        return <Route exact path={path} component={component} />
    }else{
        return <Redirect to="/" />
    }
}
function ADMIN_Routes({component, path}){
    const is_admin = useSelector(p => p.app.is_admin)
    if(is_admin){
        return <Route exact path={path} component={component} />
    }else{
        return <Redirect to="/" />
    }
}
