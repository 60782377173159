import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import {getTransaction, saveTransactionFile, idGenerateFileName, saveParseFile} from '../../utility'
import { Title } from 'react-admin';
import {colors} from '../../themes'
import Logo from '../../layout/Logo';
import { useDispatch, useSelector } from 'react-redux';
import { Responsive } from "responsive-react";
import {exts} from "../admin/extension"
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { useMediaQuery } from 'react-responsive'
import FormControl from '@material-ui/core/FormControl';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';



function createData(transID, name, type, createdAt, inOrOut, amount,  amount_paid, amout_receive, status, source, task_name, payment_proof, typeTransaction) {
  return {
    transID,
    name,
    type,
    createdAt,
    inOrOut,
    amount,
    amount_paid,
    amout_receive,
    status,
    source,
    task_name,
    payment_proof,
    typeTransaction
  };
}

let rows = []
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'transID',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Type',
  },
  {
    id: 'createAt',
    numeric: false,
    disablePadding: false,
    label: 'Date & Time',
  },
  {
    id: 'in',
    numeric: false,
    disablePadding: false,
    label: 'Partner',
  },
  {
    id: 'amount',
    numeric: true,
    disablePadding: false,
    label: 'Amount',
  },
  {
    id: 'amount_paid',
    numeric: true,
    disablePadding: false,
    label: 'Fees',
  },
  {
    id: 'amout_receive',
    numeric: true,
    disablePadding: false,
    label: 'Total',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'source',
    numeric: true,
    disablePadding: false,
    label: 'Source or connection',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'left' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell
            align={'left'}
            padding={'normal'}
          >
            <TableSortLabel
            >
              Actions
            </TableSortLabel>
          </TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Transaction
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            {/* <DeleteIcon /> */}
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function Transaction() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const  numberItem = useSelector(p =>p.app?.number_transaction)
  const [file_name, setName] = React.useState("");
  const currentUser = useSelector(state => state.app.currentUser)

  const tabData = useSelector(p =>{
    const transcations = p.app?.translations
    const locRow = [];
    if(transcations && currentUser){ 
      for(let i = 0; i < transcations.length; i++){
          const tem =  transcations[i]
          //const date =  String(new Date(tem.createdAt).toString().split('GMT')[0])
          const task = tem.transactionType?.task_name
          
         // const format = 
         // console.log('task task task', String(new Date(tem.createdAt).toISOString()))
          const date = new Date(tem.createdAt).toLocaleString(undefined, {year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', hour12: false, minute:'2-digit', second:'2-digit'})
          
          const transactionTypesIn = ["commission", "bonus", "deposit", "payout", "reco"];
          const payment_proof = tem?.payment_proof?.url
          let inOrOut = (transactionTypesIn.includes(task) || (task === 'transfer' && tem.userMain && tem.userMain.objectId !== currentUser.objectId))  ? 'IN' : 'OUT'
          const transID = tem.transaction_id

          let fees = null
          let total = null
          let amount = null
          let transTyp = tem.transactionType?.name +" - "+inOrOut
          if(task==="transfer"){
            if(inOrOut === "OUT"){
              fees = tem.amount_paid - tem.amount//.toFixed(3)
              amount = tem.amount
              total = tem.amount_paid//.toFixed(3)
              inOrOut = tem.userSecond ? tem.userSecond: ""
            }else{
              total = tem.amount_received
              amount  = tem.amount
              fees  = "-"
              inOrOut = tem.userMain? tem.userMain_name : ""
            }
          }else if(task==="investment" || task==="payout"){
            inOrOut="MCTI_Invest"
            total = tem.amount
            fees = "-"
            amount = tem.amount
            
          }else if(task==="commission" || task==="bonus"){
            inOrOut="MCTI_OPS"
          }
          else if(task==="deposit"){
            inOrOut="MCTI_MAIN"
          }
          else if(task==="reco"){
            inOrOut="SELF [PAYOUT]"
            total = tem.amount_received
            amount  = tem.amount_received
            fees  = "-"
          }
          else if(task==="withdrawal"){
            inOrOut="MCIT_Cashout"
          }
          else{
            // total = parseFloat(tem.totald).toFixed(3)
            // fees = parseFloat(tem.amount_paid).toFixed(3)
            // amount = parseFloat(tem.amount).toFixed(3)
          }
          if(task !== "transfer" && task!=="reco"&&task!=="investment"&& task!=="payout"){
            total = tem.amount_received
            fees = tem.amount_paid
            amount = tem.amount
          }

          fees = fees === 0? "-" : fees
          total = total === 0? "-" : parseFloat(total).toFixed(2)
          amount = amount === 0? "-" : parseFloat(amount).toFixed(2)
           
          locRow.push(
            createData(
              transID, 
              tem.objectId, 
              transTyp, 
              date, 
              inOrOut, 
              amount, 
              fees, 
              total, 
              tem.transactionStatus?.name, 
              tem.location?.city, 
              tem.transactionStatus?.task_name, 
              payment_proof, task
            )
          )
      }
    }
    return  locRow
  })

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = tabData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChange = async (e, idTransaction) => {
    const file = e.target.files[0];

    var reader = new FileReader();
     reader.readAsDataURL(file);
     let result;
     reader.onload =  async  () => {
      if(exts.includes((file.name).split('.')[1])){
        const res = await saveTransactionFile(idTransaction,  {base64: reader.result})
        if(!res){
          window.alert('Error uploading contract !')
        }
        calculTabData(page, rowsPerPage)
      }else{
        window.alert('File format is not supported !')
      }
     };
     reader.onerror = function (error) {
       console.log('Error: ', error);
     };
  }

  const handleChangePage = (event, newPage) => {
    calculTabData(newPage, rowsPerPage)
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    calculTabData(0, parseInt(event.target.value, 10))
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const dispatch = useDispatch();

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - numberItem) : 0;

  async function calculTabData(page, max){
    let transcations = await getTransaction(page, max);
    console.log('transcations transcations transcations', transcations)
    dispatch({ trans: transcations, type: "SAVE_TRANSLATION" })
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Title title={<Logo title="TRANSACTIONS"/>} />
      <Paper sx={{ width: '100%', mb: 2 }}>
        <Responsive displayIn={["Laptop", "Tablet"]}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={numberItem}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                  rows.slice().sort(getComparator(order, orderBy)) */}
                {//stableSort(tabData, getComparator(order, orderBy))
                  //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  tabData.map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding={'normal'}
                        >
                          {row.transID}
                        </TableCell>
                        <TableCell align="left">{row.type}</TableCell>
                        <TableCell align="left">{row.createdAt}</TableCell>
                        <TableCell align="left">{row.inOrOut}</TableCell>
                        <TableCell align="left">{row.amount}</TableCell>
                        <TableCell align="left">{row.amount_paid}</TableCell>
                        <TableCell align="left">{row.amout_receive}</TableCell>
                        <TableCell align="left">
                          <span style={{
                              backgroundColor: row.task_name === "success" ? colors.primary :  row.task_name === "fail" ? "#C82333" : row.task_name === "pending" ? "#138496" : "#E0A800", 
                              paddingLeft: '20px', 
                              paddingRight: '20px',
                              borderRadius: '10px',
                              color: 'white',
                              fontSize: '13px',
                              marginTop: '-5px',
                              paddingTop: '5px',
                              paddingBottom: '5px'
                              }} >
                                {row.status}
                              </span>
                        </TableCell>
                        <TableCell align="left">{row.source}</TableCell>

                        <TableCell align="left">
                          {row.typeTransaction === "deposit"&&
                            <Typography component="div" variant="h5" >
                              <FormControl sx={{ m: 1, width: '100%', height: '50px' }} variant="outlined" >
                                <label for={index} >
                                    <CloudDownloadOutlinedIcon  style={{color: "#8C52FF" , transform: 'translateY(5px)', cursor: "pointer"}} />
                                </label>
                                <input 
                                  id={index} 
                                  //disabled={!!row.contract_certificate}
                                  type="file" 
                                  onChange={(e) =>handleChange(e, row.name)} 
                                  style={{display: 'none'}}
                                /> 
                                {!!row.payment_proof&&
                                  <a href={row.payment_proof} style={{fontSize: "12px"}} target="_blank">Payment proof</a>
                                }
                              </FormControl>  
                            </Typography>
                          }
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Responsive>

        {
          //Mobile view started here ********
        }



        <Responsive displayIn={["Mobile"]}>
          {tabData.map((row, index) => {
              return (
                <CardResponsiveContract
                  row={row}
                  key={index}
                  cle={index}
                  handleChange={handleChange}
                />
              );
            })}
          
        </Responsive>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={numberItem}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage={isMobile? "Rows:" : "Rows per page:"}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </Box>
  );
}



function CardResponsiveContract({row, cle, handleChange}){
  return(
    <>
      <br/>
      <Card sx={{ minWidth: 275 }} style={{borderColor: 'red'}}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {row.type} 
            <b style={{marginLeft: "20px"}}> 
              <i>{row.inOrOut}</i>
            </b>
            <span style={{
              backgroundColor: row.task_name === "success" ? colors.primary :  row.task_name === "fail" ? "#C82333" : row.task_name === "pending" ? "#138496" : "#E0A800", 
              paddingLeft: '20px', 
              paddingRight: '20px',
              borderRadius: '10px',
              color: 'white',
              fontSize: '13px',
              marginTop: '-5px',
              paddingTop: '5px',
              paddingBottom: '5px',
              float: 'right'
              }} 
            >
              {row.status}
            </span>
          </Typography>
          <Typography variant="h5" component="div">
            <Typography variant="body2" component="i">
              Amount:{" "}
            </Typography>
            {row.amount}
          </Typography>
          <Typography variant="h5" component="div">
            <Typography variant="body2" component="i">
              Amount paid:{" "}
            </Typography>
            {row.amount_paid}
          </Typography>
          <Typography variant="h5" component="div">
            <Typography variant="body2" component="i">
              Amount received:{" "}
            </Typography>
            {row.amout_receive}
          </Typography>

          {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {row.start_end_date}
          </Typography> */}
          <Typography variant="body2">
          <i>Source or connection:</i>{" "+row.source}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            Date:{"   "}
            <span style={{textAlign: 'center'}} >
              {row.createdAt}
            </span>
            </Typography>
        </CardContent>
        <CardActions>
          <Typography component="div" variant="h5" >
            <FormControl sx={{ m: 1, width: '100%', height: '50px' }} variant="outlined" >
              <label for={cle} >
                  <CloudDownloadOutlinedIcon  style={{color: "#8C52FF" , transform: 'translateY(5px)', cursor: "pointer"}} />
              </label>
              <input 
                id={cle} 
                //disabled={!!row.contract_certificate}
                type="file" 
                onChange={(e) =>handleChange(e, row.name)} 
                style={{display: 'none'}}
              /> 
              {!!row.payment_proof&&
                <a href={row.payment_proof} style={{fontSize: "12px"}} target="_blank">Payment proof</a>
              }
            </FormControl>  
          </Typography>
        </CardActions>
      </Card>
    </>
  )
}
