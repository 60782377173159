import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@material-ui/core/Slide';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import './PackRia.css'
import ReactTooltip from 'react-tooltip';
import {colors, styleMain} from '../themes'
import { packagesLocale } from './Packages';
import {InvestModal} from './InvestModal'
import { useSelector } from 'react-redux';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const Price = (props) =>{
    const [open, setOpen] = React.useState(false)
    const hideDialog = (roload) => {
        setOpen(false)
        if(roload){
            props.handleClose(roload)
        }
    }
    return( 
        <Grid item md={4} xs={12}>
            <div class="section-plan">
                <div className="card">
                    <div className="card-top">
                        <h2>
                            {props.name}
                        </h2>
                    </div>
                    <div className="plan-content">
                        <div className="d-flex">
                            <ul className="nav-list">
                                <li>
                                    Amount (RIA)
                                    
                                <img
                                    src="https://www.mctibv.com/img/quest2.png"
                                    alt=""
                                    data-for={"main"+props.id0}
                                    data-iscapture="true"
                                    data-tip={props.t0}
                                    />
                                </li>
                                <li>Annual return
                                        <img
                                    src="https://www.mctibv.com/img/quest2.png"
                                    alt=""
                                    data-for={"main"+props.id1}
                                    data-iscapture="true"
                                    data-tip={props.t1}
                                    />
                                </li>
                                <li>Maturity (month)
                                        <img
                                    src="https://www.mctibv.com/img/quest2.png"
                                    alt=""
                                    data-for={"main"+props.id2}
                                    data-iscapture="true"
                                    data-tip={props.t2}
                                    />
                                </li>
                                <li>Preferred stock
                                        <img
                                    src="https://www.mctibv.com/img/quest2.png"
                                    alt=""
                                    data-for={"main"+props.id3}
                                    data-iscapture="true"
                                    data-tip={props.t3}
                                    />
                                </li>
                                <li>Cumulative stock
                                        <img
                                    src="https://www.mctibv.com/img/quest2.png"
                                    alt=""
                                    data-for={"main"+props.id4}
                                    data-iscapture="true"
                                    data-tip={props.t4}
                                    />
                                </li>
                                <li>Participating stock
                                        <img
                                    src="https://www.mctibv.com/img/quest2.png"
                                    alt=""
                                    data-for={"main"+props.id5}
                                    data-iscapture="true"
                                    data-tip={props.t5}
                                    />
                                </li>
                                <li>Convertible stock
                                        <img
                                    src="https://www.mctibv.com/img/quest2.png"
                                    alt=""
                                    data-for={"main"+props.id6}
                                    data-iscapture="true"
                                    data-tip={props.t6}/>
                                </li>
                                <li>Weekly Payout (Year)
                                        <img
                                    src="https://www.mctibv.com/img/quest2.png"
                                    alt=""
                                    data-for={"main"+props.id10}
                                    data-iscapture="true"
                                    data-tip={props.t10}
                                    />
                                </li>
                                <li>Total monthly cash-out (12x)
                                        <img
                                    src="https://www.mctibv.com/img/quest2.png"
                                    alt=""
                                    data-for={"main"+props.id7}
                                    data-iscapture="true"
                                    data-tip={props.t7}
                                    />
                                </li>
                                <li>Total yearly cash-out (1x)
                                        <img
                                    src="https://www.mctibv.com/img/quest2.png"
                                    alt=""
                                    data-for={"main"+props.id8}
                                    data-iscapture="true"
                                    data-tip={props.t8}
                                    />
                                </li>
                            </ul>
                            <ul className="nav-list nav-list1">
                                <li>{props.v1}x</li>
                                <li>{props.v2}% </li>
                                <li>{props.v3} </li>
                                <li>{props.v4} </li>
                                <li>{props.v5} </li>
                                <li>{props.v6} </li>
                                <li>{props.v7} </li>
                                <li>{(props.v9/52).toFixed(2)} </li>
                                <li>{props.v8} </li>
                                <li>{props.v9} </li>
                            </ul>
                        </div>
                        <Button onClick={() => setOpen(true)} variant="contained"  style={{backgroundColor: colors.primary, color: 'white', ...styles.customButton}}>Choose the plan</Button>
                    </div>
                </div>
            </div>
            <InvestModal open={open} hideDialog={hideDialog} pack={props}/>
        </Grid>
    )
}

export default function PackRIA(props) {

  const handleClickOpen = () => {
    props.onChangeState(true)
  };

  const handleClose = (reload) => {
    props.onChangeState(false, reload)
  };

  const packages = useSelector(p => p.app.packages)

  return (
    <div>
       
      <Dialog
        fullScreen
        open={props.open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar style={{backgroundColor: colors.primary}}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
                Investment plan
            </Typography>
          </Toolbar>
        </AppBar>
        <div>
        <div class="container">
            <Box sx={{ flexGrow: 1 }} style={{alignItems: "center", justifyContent: 'center', paddingTop: '90px'}}>
                <Grid container spacing={2}>
                    {packages && packages.length > 0 && packages.map((p, i) =>
                        <Price 
                            handleClose={handleClose}
                            task_name={p.task_name}
                            key={i}
                            name={p.pack}
                            t0={packagesLocale.filter(a => a.task_name === p.task_name)[0].t0}
                            t1={packagesLocale.filter(a => a.task_name === p.task_name)[0].t1}
                            t2={packagesLocale.filter(a => a.task_name === p.task_name)[0].t2}
                            t3={packagesLocale.filter(a => a.task_name === p.task_name)[0].t3}
                            t4={packagesLocale.filter(a => a.task_name === p.task_name)[0].t4}
                            t5={packagesLocale.filter(a => a.task_name === p.task_name)[0].t5}
                            t6={packagesLocale.filter(a => a.task_name === p.task_name)[0].t6}
                            t7={packagesLocale.filter(a => a.task_name === p.task_name)[0].t7}
                            t8={packagesLocale.filter(a => a.task_name === p.task_name)[0].t8}
                            t10={packagesLocale.filter(a => a.task_name === p.task_name)[0].t10}

                            v1={p.investment_asset}
                            v2={p.annual_roi}
                            v3={p.duration}
                            v4={packagesLocale.filter(a => a.task_name === p.task_name)[0].v4}
                            v5={packagesLocale.filter(a => a.task_name === p.task_name)[0].v5}
                            v6={packagesLocale.filter(a => a.task_name === p.task_name)[0].v6}
                            v7={packagesLocale.filter(a => a.task_name === p.task_name)[0].v7}
                            v8={p.cumul_monthly_payout}
                            v9={p.one_time_payout}
                            id0="0"
                            id1="1"
                            id1="1"
                            id2="2"
                            id3="3"
                            id4="4"
                            id5="5"
                            id6="6"
                            id7="7"
                            id8="8"
                            id10="9"
                        />
                    )}
                </Grid>
            </Box>
        </div>
        </div>
        {Array(82).fill().map((a, i) => a = i).sort(() => Math.random() - 0.5).sort((a, b)=>a-b).map(i=>
            <ReactTooltip
                id={"main"+i}
                place={"top"}
                type={"dark"}
                effect={"float"}
                multiline={true}
            />
        )}
      </Dialog>
    </div>
  );
}



const styles = {
    card: {
        backgroundColor: colors.secondary, 
        textAlign: 'center',
        // width: "200px"
        
    },
    button: {
        textDecoration: 'none'
    },
    customButton: {
        width: "180px"
    }
}