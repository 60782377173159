import Parse, {config} from '../config'
import store from '../reducer'

const Transaction = Parse.Object.extend("Transaction");
const Contract = Parse.Object.extend("Contract");
const TransactionType = Parse.Object.extend("TransactionType");
const InvestmenPlan = Parse.Object.extend("InvestmenPlan");

const  getUserSecure1 = async(userID) =>{
    try {
        console.log('userID userID userID', userID)
        const query = new Parse.Query(Parse.User)
        query.include('userSecure')
        let res = await query.get(userID)
        console.log('current User res DTATASET', res)
        res = {...res.toJSON()}
        store.dispatch({type: "CURRENT_USER", currentUser: res})
        return res
    } catch (error) {
        console.log('error geting usersecure', error)
    }
}
export const getUserSecure = getUserSecure1

export async function refleshDATASET(){
    try {
        console.log('started refresh dataset')
        await store.dispatch({type: "RELOAD", state: true})
        //console.log('refresh refleshDATASET started here')
        const us = await getUserSecure1(Parse.User.current()._getId())
        store.dispatch({type: "RELOAD", state: false})
        //console.log('result found', us)
        return us;
    } catch (error) {
        store.dispatch({type: "NEED_TO_RELOAD"})
        //console.log('error geting refleshDATASET', error)
    }
}
export async function WAIT(arg0){
    try {
        setTimeout(arg0.function, 3000);
    } catch (error) {
        store.dispatch({type: "NEED_TO_RELOAD"})
        //console.log('error geting WAIT', error)
    }
}
export async function saveKey(key, val){
    try {
        const user =  Parse.User.current();
        user.set(key, val)
        await user.save()
        await getUserSecure1(user._getId())
        //console.log('******************TGH*******************', user.get(key))
    } catch (error) {
        store.dispatch({type: "NEED_TO_RELOAD"})
        console.log('error saveKey', error)
    }
}
export async function saveContractFile(idContract, parseFile){
    try {
        const query =  new Parse.Query(Contract);
        const contract = await query.get(idContract)
        contract.set("contract_certificate", parseFile)
        const r = await contract.save()
        console.log('rrrrrrrrrrrrrrrrrrrrrrrrrrrrr', r)
        return true
    } catch (error) {
        store.dispatch({type: "NEED_TO_RELOAD"})
        console.error('error saveContractFile', error)
        return false
    }
}
export async function saveTransactionFile(idTrans, file){
    try {
        store.dispatch({ type: "RELOAD", state: true})
        const params = {
            user_id: Parse.User.current()._getId(),
            transaction_id: idTrans,
            file: file
        }
        const result = await Parse.Cloud.run('savePaymentProof', params)
        console.log('rrrrrrrrrrrrrrrrrrrrrrrrrrrrr', result)
        store.dispatch({ type: "RELOAD", state: false})
        return result
    } catch (error) {
        store.dispatch({type: "NEED_TO_RELOAD"})
        console.error('error saveContractFile', error)
        return false
    }
}
export async function saveParseFile(name, file){
    try {
        //console.log('name name parse file name file', name)
        const parseFile = new Parse.File(name, file);
        const f3 = await parseFile.save()
        //console.log('file 3 parse file save', f3)
        return f3
    } catch (error) {
        store.dispatch({type: "NEED_TO_RELOAD"})
        console.error('error saveParseFile', error, name)
    }
}
export async function idGenerateFileName(prefix, fileName){
    try {
        const user = await Parse.User.current();
        return '_FILE_.'+user._getId()+prefix+'.'+fileName.split('.')[1]
    } catch (error) {
        store.dispatch({type: "NEED_TO_RELOAD"})
        console.error('error saveParseFile', error)
    }
}

export async function getTransaction(page, max=5){
    try {
        store.dispatch({type: "RELOAD", state: true})

        const q1 = new Parse.Query(Transaction)
        
        // if(page !== 0){
        //     q1.skip(page*max)
        // }
        // q1.limit(max)
        q1.include('userSecond')
        q1.include('userMain')
        q1.descending('createdAt')
        q1.include('transactionStatus')
        q1.include('transactionType')

        const q2 = new Parse.Query(Transaction)
        
        // if(page !== 0){
        //     q2.skip(page*max)
        // } 
        // q2.limit(max)
        q2.include('userSecond')
        q2.include('userMain')
        q2.include('transactionStatus')
        q2.include('transactionType')
        

        const user =  Parse.User.current()
        console.log('countTransactionUser 2****user', user)
        q1.equalTo('userMain', user)
        q2.equalTo('userSecond', user)

        let transactionType = new Parse.Query(TransactionType);

        transactionType.equalTo("task_name", "ria_release");

        const mainQuery =  Parse.Query.or(q1, q2)
        mainQuery.include('approuvers')
        mainQuery.doesNotMatchQuery('transactionType', transactionType)
        mainQuery.limit(max)
        mainQuery.descending('createdAt')
        if(page !== 0){
            mainQuery.skip(page*max)
        }
        let res = await mainQuery.find() 
        let tab = []
        for(let i = 0; i< res.length; i++){
            
            const y = await res[i].get('transactionStatus').fetch()
            const t = await res[i].get('transactionType').fetch()
            let userSecond = ""
            if(res[i].get('userSecond')){
                userSecond = await Parse.Cloud.run('getUserById', 
                    {
                        user_id: Parse.User.current()._getId(),
                        user2_id: res[i].get('userSecond')._getId()
                    }
                )
                userSecond = userSecond&&userSecond.user2 && userSecond.userID+" ["+userSecond.user2+"]" 
            }

            let userMain_objx = ""
            if(res[i].get('userMain')){
                userMain_objx = await Parse.Cloud.run('getUserById', 
                    {
                        user_id: Parse.User.current()._getId(),
                        user2_id: res[i].get('userMain')._getId()
                    }
                )
                console.log('userMain_objx', userMain_objx)
                userMain_objx =  userMain_objx &&userMain_objx.user2&& userMain_objx.userID+" ["+userMain_objx.user2+"]"
            }
            
            // const x = []
            // res[i].relation('approuvers').query().each(function(o) {
            //     x.push(o.get('last_name')+''+o.get('first_name'))
            //  });

            tab.push({...res[i].toJSON(), transactionStatus: y.toJSON(), transactionType: t.toJSON(), userSecond: userSecond, userMain_name: userMain_objx})
            //console.log('---------------------', tab)
            
        }
        store.dispatch({type: "RELOAD", state: false})
        store.dispatch({ trans: tab, type: "SAVE_TRANSLATION" })
        return tab;
    } catch (error) {
        store.dispatch({type: "NEED_TO_RELOAD"})
        store.dispatch({type: "RELOAD", state: false})
        console.log('error fetchHistory', error)
    }
}
export async function countTransactionUser(){
    try {
        store.dispatch({type: "RELOAD", state: true})
        const q1 = new Parse.Query(Transaction)
        
        q1.descending('createdAt')
        q1.include('userSecond')
        q1.include('userMain')
        q1.descending('createdAt')
        q1.include('transactionStatus')
        q1.include('transactionType')

        const q2 = new Parse.Query(Transaction)
        
        q2.descending('createdAt')
        q2.include('userSecond')
        q2.include('userMain')
        q2.descending('createdAt')
        q2.include('transactionStatus')
        q2.include('transactionType')

        const user =  Parse.User.current()
        console.log('user user countTransactionUser', user)
        q1.equalTo('userMain', user)
        q2.equalTo('userSecond', user)

        const mainQuery =  Parse.Query.or(q1, q2)

        let count = await mainQuery.count()
        store.dispatch({type: "RELOAD", state: false})
        store.dispatch({ number: count, type: "COUNT_TRANSACTION" })

        return count
    } catch (error) {
        store.dispatch({type: "NEED_TO_RELOAD"})
        //console.log('error fetchHistory', error)
    }
}
export async function countUsers(kycState=null){
    try {
        store.dispatch({type: "RELOAD", state: true})
        const query = new Parse.Query(Parse.User)
        if(kycState){
            if(kycState==="submitted"){
                query.equalTo('kycSubmitted', true)
            }
            if(kycState==="no"){
                query.doesNotExist('kycSubmitted')
            }
        }

        let count = await query.count()
        store.dispatch({type: "RELOAD", state: false})

        return count
    } catch (error) {
        store.dispatch({type: "NEED_TO_RELOAD"})
        //console.log('error fetchHistory', error)
    }
}

export async function getContract(filter){
    try {
        store.dispatch({type: "RELOAD", state: true})
        const query = new Parse.Query(Contract)
        const user = await Parse.User.current()
        query.include('user')
        query.descending('createdAt')
        query.equalTo('user', user)
        const current_date = new Date();
        if(filter && filter === 'pass'){
        
            query.lessThan('start_date', current_date);
        }
        if(filter && filter === 'active'){
            query.greaterThan('start_date', current_date);
        }
        let res = await query.find()
        let tab = []
        let status = "On Going";
        for(let i = 0; i< res.length; i++){
            
            if(new Date (res[i].toJSON()?.end_date?.iso) < current_date){
                
                status = "Expired";
            }
            const contract_certificate = res[i].toJSON()?.contract_certificate?.url
            
            const obx = {
                id: res[i].toJSON().contract_id,
                Start_end_date: res[i].toJSON()?.start_date?.iso.split('T')[0]+" - "+res[i].toJSON()?.end_date?.iso.split('T')[0],
                volume:  res[i].toJSON()?.volume,
                weekly_roi: res[i].toJSON()?.weekly_roi,
                status: status,
                contract_certificate: contract_certificate
            }
            tab.push(obx)
        }
        //console.log('tab tab tabtabv tab tab tab tab', tab)
        store.dispatch({type: "RELOAD", state: false})
        store.dispatch({ contacts: tab, type: "SAVE_CONTRACTS" })
        return tab;
    } catch (error) {
        store.dispatch({type: "NEED_TO_RELOAD"})
        store.dispatch({type: "RELOAD", state: false})
        //console.log('error fetchHistory', error)
    }
}


export async function saveLastLoaction(){
    try{
        const response = await fetch('https://api.ipify.org');
        const ip = await response.text();
        const result = await Parse.Cloud.run('iplocation',  { ip: ip })
        console.log('result result saveLastLoaction', result)
        const user = Parse.User.current();
        user.set('last_location', result && !result.error? result.location : {})
        user.save();
    }catch(e){

    }
}
export async function Logout(_kc){
    //console.log('started logout herrre')
    try{
        Parse.User.logOut().then(() => {
            //console.log('after logout after logout Parse.User.current()', Parse.User.current())
            _kc.logout(config['auth-server-url']+'/realms/Internal_Projects/protocol/openid-connect/logout?redirect_uri=encodedRedirectUri')
        });
    }catch(e){

    }
}
export async function processPayment(obj){
    try{
        const params = {
            investment_plan_task_name: obj.task_name,
            investment_number: parseInt(obj.packNumber),
            is_monthly_payment_terms: obj.payment === "monthly" ? true : false,
            user_id: Parse.User.current()._getId()
        }
        //console.log('params params params params', params)
        const result = await Parse.Cloud.run('subscribeInvestmentRequest', params)
        return result
    }catch(e){
        //console.log('errrorr subscribeInvestmentRequest', e)
    }
}
export async function getMainAccountInfos(obj){
    try{
        store.dispatch({type: "RELOAD", state: true})
        const params = {
            user_id: Parse.User.current()._getId()
        }
        //console.log('params params params params', params)
        const result = await Parse.Cloud.run('getMainAccountInfos', params)
        store.dispatch({type: "RELOAD", state: false})
        return result
    }catch(e){
        console.log('errrorr subscribeInvestmentRequest', e)
    }
}
export async function onProcessMainRechargeRIA(ria_amount, reason){
    try{
        const params = {
            user_id: Parse.User.current()._getId(),
            ria_amount: ria_amount,
            reason: reason
        }
        store.dispatch({type: "RELOAD", state: true})
        //console.log('params params params params', params)
        const result = await Parse.Cloud.run('mainRechargeRIA', params)
        store.dispatch({type: "RELOAD", state: false})
        return result
    }catch(e){
        store.dispatch({type: "RELOAD", state: false})
        console.log('errrorr subscribeInvestmentRequest', e)
    }
} 
export async function listOfMyTeam(){
    try{
        const obj = {user_id: Parse.User.current()._getId()}
        const results = await Parse.Cloud.run('listOfMyTeam', obj)

        
        const locRow = []
        if(results.childs){
            for(let i = 0; i < results.childs.length; i++){
                const loc = results.childs[i]

                //String(loc.createdAt.split('T')[0])
                const date =  String(new Date(loc.createdAt).toString().split('GMT')[0])
                locRow.push(
                    {
                        name: loc?.account_id,
                        status: loc?.kycValidated ? "Verified" : "Not Verified",
                        level: loc.level,
                        date: date
                    }
                )
            }
            store.dispatch({type: "LIST_TEAM", data: locRow})
        }
        return results.childs
    }catch(e){
        console.log('errrorr listOfMyTeam loadtem', e)
    }
}
export async function buyRIA(obj){
    try{
        const params = {
            ria_amount: parseInt(obj.amount),
            paymentMethod: obj.paymentMethod,
            user_id: Parse.User.current()._getId()
        }
        //console.log('params params params params', params)
        const result = await Parse.Cloud.run('buyRIA', params)
        return result
    }catch(e){
        //console.log('errrorr buyRIA', e)
    }
}
export async function sendComment(obj){
    try{
        const params = {
            user_id: Parse.User.current()._getId(),
            ...obj
        }
        console.log('params params params params', params)
        const result = await Parse.Cloud.run('sendTopUpTransactionComment', params)
        return result
    }catch(e){
        return {error: true, message: "undifined error !"}
        //console.log('errrorr buyRIA', e)
    }
}
export async function inviteFriend(emails){
    try{
        const params = {
            emails: emails,
            user_id: Parse.User.current()._getId()
        }
        //console.log('params params params params', params)
        const result = await Parse.Cloud.run('sendEmailInviteFriend', params)
        //console.log('result', result)
        return result
    }catch(e){
        //console.log('errrorr buyRIA', e)
    } 
}
export async function getTransferRiaInfos(object){
    try{
        const params = {
            ...object,
            user_id: Parse.User.current()._getId()
        }
        const result = await Parse.Cloud.run('getTransferRiaInfos', params)
        //console.log('result', result, params)
        return result
    }catch(e){
        //console.log('errrorr buyRIA', e)
    }
}
export async function verificationOTP(object){
    try{
        const params = {
            ...object,
            otp: object.otpCode,
            user_id: Parse.User.current()._getId()
        }
        store.dispatch({type: "RELOAD", state: true})
        const result = await Parse.Cloud.run('verificationOTP', params)
        store.dispatch({type: "RELOAD", state: false})
        console.log('result', params, result)
        return result
    }catch(e){
        //console.log('errrorr buyRIA', e)
    }
}
export async function transferRIA(object){
    try{
        const params = {
            ...object,
            user_id: Parse.User.current()._getId()
        }
        const result = await Parse.Cloud.run('transferRIA', params)
        //console.log('result', result, params)
        return result
    }catch(e){
        //console.log('errrorr buyRIA', e)
    }
}

async function localisable () {
    try {
      var requestUrl = "http://ip-api.com/json";
      const response = await fetch(requestUrl, {
        method: 'GET', 
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return response.json(); 
    } catch (error) {
        store.dispatch({type: "NEED_TO_RELOAD"})
      //console.log('errorr localisable', error)
    }
}

export async function getUserList(page, max, kycState){
    console.log('getUserList getUserList ***********', page, max, kycState)
    try {
        store.dispatch({type: "RELOAD", state: true})
        const query = new Parse.Query(Parse.User)
        query.include('userSecure')
        if(page !== 0){
            query.skip(page*max)
        }
        if(kycState){
            if(kycState==="submitted"){
                query.equalTo('kycSubmitted', true)
            }
            if(kycState==="no"){
                query.doesNotExist('kycSubmitted')
            }
        }
        query.limit(max)
        query.descending('createdAt')
        query.include('id_document_type')
        let res = await query.find()
        let tab = []
        for(let i = 0; i< res.length; i++){
            console.log('user user ***************', res[i].get('email'))
            tab.push(res[i].toJSON())
        }
        store.dispatch({type: "RELOAD", state: false})
        return tab
    } catch (error) {
        store.dispatch({type: "NEED_TO_RELOAD"})
        console.error('error getUserList', error)
    }
}
export async function getTransList(page, max){
    console.log('getTransList getTransList getTransList started')
    try {
        store.dispatch({type: "RELOAD", state: true})
        const query = new Parse.Query(Transaction)

        let transactionType = new Parse.Query(TransactionType);

        if(!store.getState().app.is_admin){
            transactionType.equalTo("task_name", "ria_release");
            query.include('approuvers')
            query.doesNotMatchQuery('transactionType', transactionType)
        }

        if(page !== 0){
            query.skip(page*max)
        }
        query.limit(max)
        query.descending('createdAt')
        query.include('userMain')
        query.include('userSecond')
        query.include('transactionStatus')
        query.include('approuvers')
        query.include('transactionType')
        query.include('investmenPlan')
        let res = await query.find()
        //console.log('res res res res', res)
        let tab = []
        for(let i = 0; i< res.length; i++){
            const x = []
            await res[i].relation('approuvers').query().each(function(o) {
                x.push((o.get('last_name')?o.get('last_name'):'')+''+(o.get('first_name')?o.get('first_name'):""))
            });
            tab.push({...res[i].toJSON(), validator: x})
        }
        store.dispatch({type: "RELOAD", state: false})
        return tab
    } catch (error) {
        store.dispatch({type: "NEED_TO_RELOAD"})
        //console.log('error fetchHistory', error)
    }
}
export async function countTransaction(){
    try {
        store.dispatch({type: "RELOAD", state: true})
        const query = new Parse.Query(Transaction)

        let count = await query.count()
        //console.log('count count count count count count', count)
        store.dispatch({type: "RELOAD", state: false})

        return count
    } catch (error) {
        store.dispatch({type: "NEED_TO_RELOAD"})
        //console.log('error fetchHistory', error)
    }
}
export async function countContract(){
    try {
        store.dispatch({type: "RELOAD", state: true})
        const query = new Parse.Query(Contract)

        let count = await query.count()
        //console.log('count count count count count count', count)
        store.dispatch({type: "RELOAD", state: false})

        return count
    } catch (error) {
        store.dispatch({type: "NEED_TO_RELOAD"})
        //console.log('error fetchHistory', error)
    }
}

export async function getContractAdmin(page, max){
    try {
        store.dispatch({type: "RELOAD", state: true})
        const query = new Parse.Query(Contract)
        if(page !== 0){
            query.skip(page*max)
        }
        query.limit(max)
        query.include('user')
        query.descending('createdAt')
        query.include('investmenPlan')
        query.include('transaction')
        query.descending('createdAt')
        let res = await query.find()
        let tab = []
        for(let i = 0; i< res.length; i++){
            tab.push(res[i].toJSON())
        }
        //console.log('*****************************************', tab)
        store.dispatch({type: "RELOAD", state: false})
        return tab; 
    } catch (error) {
        store.dispatch({type: "NEED_TO_RELOAD"})
        //console.log('error fetchHistory', error)
    }
}
export async function changeUserStatus(investor_id, comment, sendMail){
    try {
        store.dispatch({type: "RELOAD", state: true})
        const obj = {investor_id: investor_id, user_id: Parse.User.current()._getId(), comment: comment, sendMail: sendMail}
        const result = await Parse.Cloud.run('changeUserStatus', obj)
        console.log('changeUserStatus changeUserStatus', result)
        store.dispatch({type: "RELOAD", state: false})
        //console.log('result result resultresult', result)
        return result;
    } catch (error) {
        store.dispatch({type: "NEED_TO_RELOAD"})
        console.log('error changeUserStatus', error)
    }
}
export async function deteteAnAccount(){
    try {
        const obj = {user_id: Parse.User.current()._getId()}
        const result = await Parse.Cloud.run('deteteAnAccount', obj)
        console.log('deteteAnAccount deteteAnAccount', result)
        return result;
    } catch (error) {
        store.dispatch({type: "NEED_TO_RELOAD"})
        console.console.error('error deteteAnAccount', error)
    }
}
export async function validateDepositTransaction(transaction_id, comment){
    try {
        store.dispatch({type: "RELOAD", state: true})
        const obj = {transaction_id: transaction_id, user_id: Parse.User.current()._getId()}
        const result = await Parse.Cloud.run('validateDepositTransaction', obj)
        store.dispatch({type: "RELOAD", state: false})
        console.log('validateDepositTransaction validateDepositTransaction', result)
        return result;
    } catch (error) {
        store.dispatch({type: "NEED_TO_RELOAD"})
        //console.log('error fetchHistory', error)
    }
}
export async function ChangeTransactionStatus(transaction_id, comment, cloud, sendMail){
    try {
        store.dispatch({type: "RELOAD", state: true})
        const obj = {transaction_id: transaction_id, user_id: Parse.User.current()._getId(), comment: comment, sendMail: sendMail}
        const result = await Parse.Cloud.run(cloud, obj)
        store.dispatch({type: "RELOAD", state: false})
        //console.log('result result resultresult', result)
        return result;
    } catch (error) {
        //store.dispatch({type: "NEED_TO_RELOAD"})
        console.log('error fetchHistory', cloud, error)
    }
}
export async function getSporsorId(){
    try {
        store.dispatch({type: "RELOAD", state: true})
        const obj = {user_id: Parse.User.current()._getId()}
        const result = await Parse.Cloud.run("getSporsorId", obj)
        store.dispatch({type: "RELOAD", state: false})
        console.log('result result resultresult loadtem', result, obj)
        return result;
    } catch (error) {
        //store.dispatch({type: "NEED_TO_RELOAD"})
        console.log('error fetchHistory loadtem', error)
    }
}

export async function listPack(){
    try {
        const query = new Parse.Query(InvestmenPlan)

        const res = await query.find();
        const tab = [];
        res.map((r, i) =>{
            tab.push(r.toJSON())
        })
        console.log('tab tab listPack listPacklistPack listPack', tab)
        store.dispatch({type: "SAVE_PACK", packages: tab})
        
    } catch (error) {
        console.log('errorrr pack ria', error)
    }
}