
const defaultState = {
    currentUser: null,
    navigateAgain:  true,
    contacts: [],
    _kc: null,
    reload: false,
    show_admin_menu: false,
    translations: [], 
    number_transaction: 0,
    need_to_reload: false,
    transactionEdited: [],
    transactionID: null,
    transactionState: '',
    listTeam: [], 
    packages: []
}
  
export default function app(state = defaultState, action) {
  switch (action.type) {
    case 'AUTH_SUCCESS' :
      return {...state, auth: action.auth}
    case 'AUTH_FAILLED' :
      return {...state, currentUser: null, auth: false}
    case 'ALREADY_NAVIGATE' :
      return {...state, navigateAgain: false}
    case 'CURRENT_USER' :
      return {...state, currentUser: action.currentUser}
    case 'SAVE_CONTRACTS' :
      return {...state, contacts: action.contacts}
    case '_KC_OBJECT' :
      return {...state, _kc: action._kc}
    case 'RELOAD' :
      return {...state, reload: action.state}
    case 'SHOW_ADMIN_MENU' :
      return {...state, show_admin_menu: true}
    case 'SAVE_TRANSLATION' :
      return {...state, translations: action.trans}
    case 'COUNT_TRANSACTION' :
      return {...state, number_transaction: action.number}
    case 'COUNT_TRANSACTION' :
      return {...state, number_transaction: action.number}
    case 'NEED_TO_RELOAD' :
      return {...state, need_to_reload: true}
    case 'SESSION_RESET' :
      return {...state, need_to_reload: false}
    case 'SAVE_COMMENT_ID' :
      return {...state, transactionID: action.id}
    case 'LIST_TEAM' :
      return {...state, listTeam: action.data}
    case 'SAVE_TRANSACTION_STATE' :
      return {...state, transactionState: action.state}
    case 'IS_ADMIN' :
      return {...state, is_admin: true}
    case 'SAVE_PACK' :
      return {...state, packages: action.packages}
    case 'SAVE_TRANSACTION_EDITED' :
      const transactionEdited = state.transactionEdited
      transactionEdited.push(action.id)
      return {...state, transactionEdited: transactionEdited}
    default:
      return state
  }
}