import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { TextInput  } from 'react-admin';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Title } from 'react-admin';
import { Form } from 'react-final-form' 
import {colors, stylesMain} from '../../themes'
import ActibitiesHistory from "../../components/ActibitiesHistory";
import EdiText from 'react-editext';
import ReactTooltip from 'react-tooltip';
import {InputAdornments, DocType, File, Country} from '../../components/ProfileComponent'
import {saveKey} from '../../utility' 
import {config, WEBSITE_URL, INVIATION_SHORT_LINK} from '../../config'
import Share from '../../components/Share';
import Logo from '../../layout/Logo';
import ListTeam from '../../components/ListTeam'
import { deteteAnAccount, getSporsorId, listOfMyTeam } from '../../utility'
import { useMediaQuery } from 'react-responsive'

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';


  
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const style = {
    width: '100%',
    maxWidth: "100%",
    bgcolor: 'background.paper',
  };


const Profile = (props) =>{
    const currentUser = useSelector(state => state.app.currentUser)

    const isMobile = useMediaQuery({ maxWidth: 767 })

    
    console.log('currentUser currentUser currentUser', currentUser)
    const [pin, setPin] = useState("****");
    const [ans1, setAns1] = useState(currentUser?.security_question_answer_1)
    const [ans2, setAns2] = useState(currentUser?.security_question_answer_2);

    const [proposer, setProposer] = useState(null);

    const [quest1, setQuest1] = useState(currentUser?.security_question_1)
    const [quest2, setQuest2] = useState(currentUser?.security_question_2);

    const [copytext, setText] = useState("Copies referal link to clipboard");
    const [copieAccountIDText, setCopieAC] = useState("Copies Account ID to clipboard");
    const [docType, setDoctYpe] = useState("");

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [referal_link, setReferal] = useState('')
    React.useEffect(() =>{
        (async()=>{
            https://webdev.mctibv.com/update-infos?referal_id=BLXAFYHiSx
            setReferal(WEBSITE_URL+INVIATION_SHORT_LINK+'?referral_id=')
            const proposer = await getSporsorId()
            setProposer(proposer? proposer.proposer : null)
            console.log('proposer proposer proposer', proposer)
        })()
    }, [])

    const onSubmit = () => {
 
    }
    const validate = () => {

    }

    const handleSave = (val) => {
        console.log('Edited Value -> ', val);
        setPin("****");
        saveKey('pin_code', val)

    };
    const handleSaveAns1 = (val) => {
        console.log('Edited Value -> ', val);
        setAns1(val);
        saveKey('security_question_answer_1', val)
    };
    const handleSaveAns2 = (val) => {
        console.log('Edited Value -> ', val);
        setAns2(val);
        saveKey('security_question_answer_2', val)
    };
    const handleSaveQuest1 = (val) => {
        console.log('Edited Value -> ', val);
        setQuest1(val);
        saveKey('security_question_1', val)
    };
    const handleSaveQuest2 = (val) => {
        console.log('Edited Value -> ', val);
        setQuest2(val);
        saveKey('security_question_2', val)
    };

    const myFunction = () => {
        navigator.clipboard.writeText(referal_link+currentUser?.userSecure?.account_id);
        setText('Copied to clipboard')
    }
    const copieAccountID = (text) => {
        navigator.clipboard.writeText(text);
        setCopieAC('Account ID copied to clipboard')
    }
    const onConfirmDeletion = () => {
        if(window.confirm("Do you really want to delete your account ?")){
            deteteAnAccount()
        }
    }




    return <div>
        <Title title={<Logo title="PROFILE"/>}/>
        <Box sx={{ flexGrow: 1 }} style={{paddingTop: !isMobile ?'80px' :  0}}>
            <Grid container spacing={2}>
                    <Grid item md={6}  xs={12}>  
                        <Card>
                            <CardContent>
                                <Typography component="div" variant="h5">                            
                                    Account Info

                                    <span style={{ float: 'right'}} >
                                        <span style={{
                                            backgroundColor: currentUser?.userSecure?.kycValidated ?  colors.primary : "#FF5137", 
                                            paddingLeft: '20px', 
                                            paddingRight: '20px',
                                            borderRadius: '10px',
                                            color: 'white',
                                            fontSize: '13px',
                                            marginTop: '-5px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px'
                                        }} >{currentUser?.userSecure?.kycValidated ? "Verified" : !!currentUser?.kycSubmitted ? "Pending" : 'Not Verified'}
                                        </span>
                                        {!!!currentUser?.userSecure?.kycValidated && !!!currentUser?.kycSubmitted &&
                                            <span style={{fontSize: '14px', marginLeft: '40px'}}>
                                                <a href={WEBSITE_URL+"/update-infos"} target="_blank">
                                                    Verify
                                                </a>
                                            </span>
                                        }
                                    </span>

                                </Typography>


                                <Typography component="div" variant="h6" style={{fontSize: stylesMain.fontSize, paddingBottom: '25px'}}>                            
                                    Account ID:{"  "}
                                    <>
                                        <span style={{
                                            backgroundColor: colors.primary, 
                                            paddingLeft: '20px', 
                                            paddingRight: '20px',
                                            borderRadius: '10px',
                                            color: 'white',
                                            fontSize: '13px',
                                            marginTop: '-5px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px'
                                        }} >{currentUser?.userSecure?.account_id}
                                        </span> 
                                        <button>
                                            <ContentCopyIcon 
                                                style={{ height: "30px"}}  
                                                onClick={() => copieAccountID(currentUser?.userSecure?.account_id)} 
                                                data-for={"mainCOpi"}
                                                data-iscapture="true"
                                                data-tip={"Copies account ID"}
                                            />
                                        </button>
                                        <ReactTooltip
                                            id={"mainCOpi"}
                                            place={"top"}
                                            type={"dark"}
                                            effect={"float"}
                                            multiline={true}
                                        />
                                    </>
                                </Typography>
                                {(currentUser?.userSecure?.kycValidated || currentUser?.kycSubmitted)&&
                                    <>
                                        <Box sx={{ width: '100%' }}>
                                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                                    <Tab label="Basic informations" {...a11yProps(0)} />
                                                    {/* <Tab label="Adress" {...a11yProps(1)} /> */}
                                                    <Tab label="Address" {...a11yProps(1)} />
                                                    <Tab label="Sponsor" {...a11yProps(2)} />
                                                </Tabs>
                                            </Box>
                                            <TabPanel value={value} index={0}>
                                                <>
                                                    <InputAdornments  
                                                        type='text'
                                                        label={"First name"}
                                                        cle={"first_name"}
                                                        defaultValue={currentUser?.first_name}
                                                        
                                                    />
                                                    <InputAdornments
                                                        cle={"last_name"}
                                                        type='text'
                                                        label={"Last name"}
                                                        defaultValue={currentUser?.last_name}
                                                    />
                                                    <InputAdornments
                                                        cle={"birthdate"}
                                                        type='text'
                                                        date
                                                        label={"Date of birth"}
                                                        defaultValue={(currentUser?.birthdate?.iso)?.split('T')[0]}
                                                    />
                                                    <InputAdornments
                                                        cle={"email"}
                                                        noEdit
                                                        type='text'
                                                        label={"Email"}
                                                        defaultValue={currentUser?.email}
                                                    />
                                                    <InputAdornments
                                                        type='text'
                                                        cle={"phone_number"}
                                                        label={"Telephone number"}
                                                        defaultValue={currentUser?.phone_number}
                                                    />
                                                    
                                                    <Country label='Country of nationality' defaultValue={currentUser?.country_nationality_code} cle={'country_nationality_code'}/>   
                                                    
                                                    {/* <DocType
                                                        defaultValue={currentUser?.id_document_type}
                                                        cle={"id_document_type"}
                                                        handleChangeDocType={setDoctYpe}
                                                    />
                                                    <InputAdornments
                                                        type='text'
                                                        cle="id_document_number"
                                                        label={"Document number"}
                                                        defaultValue={currentUser?.id_document_number}
                                                    />
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                                        {docType !== "passport" &&
                                                            <File label="Pick side ID" cle="id_document_1" OldUrl={currentUser?.id_document_1?.url} prefix="_sideID_" />
                                                        }
                                                        
                                                        <File label="Pick front ID" cle="id_document_2" prefix="_frontID_" OldUrl={currentUser?.id_document_2?.url}/>
                                                        <File label="Pick selfie with ID" cle="id_document_selfie" prefix="_selfie_" OldUrl={currentUser?.id_document_selfie?.url}/>
                                                    </Box> */}
                                                </>
                                            </TabPanel>
                                            {/* <TabPanel value={value} index={1}>
                                                <>
                                                    <Country label='Country address' defaultValue={currentUser?.country_address_code} cle={'country_address_code'}/>
                                        
                                                    <InputAdornments
                                                        type='text'
                                                        cle={"street"}
                                                        label={"Street"}
                                                        defaultValue={currentUser?.street}
                                                    />
                                                    <InputAdornments
                                                        type='text'
                                                        cle={"house_number"}
                                                        label={"House number"}
                                                        defaultValue={currentUser?.house_number}
                                                    />
                                                    <InputAdornments
                                                        type='text'
                                                        label={"Postal code"}
                                                        cle={"postal_code"}
                                                        defaultValue={currentUser?.postal_code}
                                                    />
                                                    <InputAdornments
                                                        type='text'
                                                        label={"Town"}
                                                        cle={"town"}
                                                        defaultValue={currentUser?.town}
                                                    />
                                                </>
                                            </TabPanel> */}
                                            <TabPanel value={value} index={1}>
                                                <>
                                                    <Country label='Country address' defaultValue={currentUser?.country_address_code} cle={'country_address_code'}/>
                                            
                                                    <InputAdornments
                                                        type='text'
                                                        cle={"street"}
                                                        label={"Street"}
                                                        defaultValue={currentUser?.street}
                                                    />
                                                    <InputAdornments
                                                        type='text'
                                                        cle={"house_number"}
                                                        label={"House number"}
                                                        defaultValue={currentUser?.house_number}
                                                    />
                                                    <InputAdornments
                                                        type='text'
                                                        label={"Postal code"}
                                                        cle={"postal_code"}
                                                        defaultValue={currentUser?.postal_code}
                                                    />
                                                    <InputAdornments
                                                        type='text'
                                                        label={"Town"}
                                                        cle={"town"}
                                                        defaultValue={currentUser?.town}
                                                    />
                                                </>
                                            </TabPanel>
                                            <TabPanel value={value} index={2}>
                                                <>
                                                    {proposer && <InputAdornments
                                                        type='text'
                                                        label={"Sponsor name"}
                                                        cle={""}
                                                        noEdit
                                                        defaultValue={proposer ? (proposer.last_name+" "+proposer.first_name) : ""}
                                                    />}
                                                    {proposer && <InputAdornments
                                                        type='text'
                                                        label={"Sponsor account ID"}
                                                        cle={""}
                                                        noEdit
                                                        defaultValue={proposer ? (proposer.userSecure.account_id) : ""}
                                                    />}
                                                </>
                                            </TabPanel>
                                        </Box>
                                    </>
                                }


                                {/* {(currentUser?.userSecure?.kycValidated || currentUser?.kycSubmitted)&&
                                    <>
                                            
                                        
                                        
                                            
                                            
                                            <DocType
                                                defaultValue={currentUser?.id_document_type}
                                                cle={"id_document_type"}
                                                handleChangeDocType={setDoctYpe}
                                            />
                                            <InputAdornments
                                                type='text'
                                                cle="id_document_number"
                                                label={"Document number"}
                                                defaultValue={currentUser?.id_document_number}
                                            />

                                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {docType !== "passport" &&
                                                <File label="Pick side ID" cle="id_document_1" OldUrl={currentUser?.id_document_1?.url} prefix="_sideID_" />
                                            }
                                            <File label="Pick front ID" cle="id_document_2" prefix="_frontID_" OldUrl={currentUser?.id_document_2?.url}/>
                                            <File label="Pick selfie with ID" cle="id_document_selfie" prefix="_selfie_" OldUrl={currentUser?.id_document_selfie?.url}/>
                                        </Box>
                                        
                                        <Country label='Country of nationality' defaultValue={currentUser?.country_nationality_code} cle={'country_nationality_code'}/>
                                       
                                            {proposer && <InputAdornments
                                                type='text'
                                                label={"My sponsor"}
                                                cle={""}
                                                noEdit
                                                defaultValue={proposer ? (proposer.last_name+" "+proposer.first_name+" - "+proposer.userSecure.account_id) : ""}
                                            />}
                                    </>
                                } */}
                            </CardContent>
                        </Card>
                    </Grid>
                
                <Grid container item md={6}  xs={12}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            {/* <Grid item xs={12}>
                                <Card>
                                    <CardContent>
                                        <Typography component="div" variant="h5">
                                            Account Info
                                            <span style={{
                                                backgroundColor: currentUser?.userSecure?.kycValidated ?  colors.primary : "#FF5137", 
                                                paddingLeft: '20px', 
                                                paddingRight: '20px',
                                                borderRadius: '10px',
                                                color: 'white',
                                                fontSize: '13px',
                                                marginTop: '-5px',
                                                paddingTop: '5px',
                                                paddingBottom: '5px'
                                            }} >{currentUser?.userSecure?.kycValidated ? "Verified" : !!currentUser?.kycSubmitted ? "Pending" : 'Not Verified'}
                                            </span>
                                            {!!!currentUser?.userSecure?.kycValidated && !!!currentUser?.kycSubmitted &&
                                                <span style={{fontSize: '14px', marginLeft: '40px'}}>
                                                    <a href={WEBSITE_URL+"/update-infos"} target="_blank">
                                                        Verify
                                                    </a>
                                                </span>
                                            }

                                       </Typography>

                                        <Form
                                            onSubmit={onSubmit}
                                            validate={validate}
                                            render={({ handleSubmit }) => (
                                                <form onSubmit={handleSubmit}>
                                                    <TextInput defaultValue={currentUser?.userSecure?.account_id} source="accountID" label="Account ID" variant="outlined" style={{width: '100%'}} disabled />
                                                    
                                                    <TextInput defaultValue={currentUser?.account_level} source="account_level" label="Account Level:" variant="outlined" style={{width: '100%'}} disabled />
                                                </form>
                                            )}

                                        />
                                    </CardContent>
                                </Card>
                            </Grid> */}
                            <Grid item xs={12}>
                                <Card>
                                    <CardContent>
                                        <Typography component="div" variant="h5">                            
                                            Activities history
                                        </Typography>
                                        
                                        <ActibitiesHistory />
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid> 
                    </Box>
                </Grid>
                <Grid item md={6}  xs={12}>
                    <Card>
                        <CardContent>
                            <Typography component="div" variant="h5">                            
                            Sponsoring
                            </Typography>

                            {/* <Typography component="div" variant="h6" style={{fontSize: stylesMain.fontSize}}>                            
                                    Account ID:{"  "}
                                    <>
                                        <span style={{
                                            backgroundColor: colors.primary, 
                                            paddingLeft: '20px', 
                                            paddingRight: '20px',
                                            borderRadius: '10px',
                                            color: 'white',
                                            fontSize: '13px',
                                            marginTop: '-5px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px'
                                        }} >{currentUser?.userSecure?.account_id}
                                        </span> 
                                        <button>
                                            <ContentCopyIcon 
                                                style={{ height: "30px"}}  
                                                onClick={() => copieAccountID(currentUser?.userSecure?.account_id)} 
                                                data-for={"mainCOpi"}
                                                data-iscapture="true"
                                                data-tip={"Copies account ID"}
                                            />
                                        </button>
                                        <ReactTooltip
                                            id={"mainCOpi"}
                                            place={"top"}
                                            type={"dark"}
                                            effect={"float"}
                                            multiline={true}
                                        />
                                    </>
                            </Typography> */}
                            <Typography component="div" variant="h6" style={{fontSize: stylesMain.fontSize, marginTop: '5px', paddingBottom: '5px'}}>                            
                                    Pending RSG:
                                        <>
                                            <span style={{
                                                paddingLeft: '10px', 
                                                paddingRight: '20px',
                                                borderRadius: '10px',
                                                color: colors.primary,
                                                fontSize: '17px',
                                                marginTop: '1px',
                                                paddingTop: '5px',
                                                paddingBottom: '5px'
                                            }} >
                                                {(parseInt(currentUser?.account_level)+1)}
                                            </span> 
                                        </>
                                </Typography>
                            {/* <Typography component="div" variant="h6" style={{fontSize: stylesMain.fontSize, marginTop: '5px', paddingBottom: '5px'}}>                            
                                    My sponsor ID:
                                        <>
                                            <span style={{
                                                paddingLeft: '10px', 
                                                paddingRight: '20px',
                                                borderRadius: '10px',
                                                color: colors.primary,
                                                fontSize: '17px',
                                                marginTop: '1px',
                                                paddingTop: '5px',
                                                paddingBottom: '5px'
                                            }} >
                                                {proposer&&proposer.userSecure&&(proposer.userSecure.account_id)}
                                            </span> 
                                        </>
                                </Typography> */}

                            {/* <Typography component="div" variant="h6" style={{fontSize: stylesMain.fontSize}}>                            
                                Sponsor’s account ID
                                {proposer&& proposer.userSecure && 
                                    <>
                                        <span style={{
                                            backgroundColor: colors.primary, 
                                            paddingLeft: '20px', 
                                            paddingRight: '20px',
                                            borderRadius: '10px',
                                            color: 'white',
                                            fontSize: '13px',
                                            marginTop: '-5px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px'
                                        }} >{proposer?.userSecure?.account_id}
                                        </span> 
                                        <button>
                                            <ContentCopyIcon 
                                                style={{ height: "30px"}}  
                                                onClick={() => copieAccountID(proposer?.userSecure?.account_id)} 
                                                data-for={"mainCOpi"}
                                                data-iscapture="true"
                                                data-tip={"Copies account ID"}
                                            />
                                        </button>
                                        <ReactTooltip
                                            id={"mainCOpi"}
                                            place={"top"}
                                            type={"dark"}
                                            effect={"float"}
                                            multiline={true}
                                        />
                                    </>
                                }
                            </Typography> */}

                            <Typography component="span" variant="h6" style={{fontSize: stylesMain.fontSize, alignItems: 'center'}}>

                                Your referral link 
                                <span style={{
                                    paddingLeft: '20px', 
                                    color: colors.primary,
                                    textDecorationColor: colors.primary,
                                    textDecoration: 'underline'
                                }}
                                id="myInput"
                                >
                                    {(referal_link+currentUser?.userSecure?.account_id).slice(0, 33)+'...'}
                                </span>
                                <div className="tooltip"> 
                                    <button>
                                        <span className="tooltiptext" id="myTooltip">{copytext}</span>
                                        <ContentCopyIcon style={{ height: "30px"}}  onClick={() => myFunction()} />
                                    </button>
                                </div>
                                 

                                <Share shareUrl={referal_link+currentUser?.userSecure?.account_id} title="Share"/>
                            </Typography>

                            <Typography component="div" variant="h6" style={{fontSize: stylesMain.fontSize}}>                            
                            Your team:
                            </Typography>
                            <ListTeam/>
                            


                        </CardContent>
                    </Card>
                </Grid>


                <Grid item md={6}  xs={12}>
                    <Card>
                        <CardContent>
                            <Typography component="div" variant="h5">                            
                            Security settings
                            </Typography>


                            {/* <Typography component="div" variant="h5" style={{fontSize: stylesMain.fontSize}}>                            
                                Enable 2-Factor authentication
                                <Tooltip placement="top-start" title="This option require Google authenticator on your mobile">
                                    <button style={{backgroundColor: 'none', borderWidth: '0px'}} >(?)</button>  
                                </Tooltip>
                                <Switch {...labelSwitch} style={{color: colors.primary}} />
                            </Typography>

                            <div style={{paddingLeft: '50px', paddingRight: '100px'}}>
                                <Divider/>
                                <Typography component="div" variant="h5" style={{fontSize: stylesMain.fontSize}}>                            
                                    <Checkbox {...labelCheck} style={{color: colors.primary}} />
                                    Login
                                </Typography>
                                <Typography component="div" variant="h5" style={{fontSize: stylesMain.fontSize}}>                            
                                    <Checkbox {...labelCheck} style={{color: colors.primary}} />
                                    RIA Transfer
                                </Typography>
                                <Typography component="div" variant="h5" style={{fontSize: stylesMain.fontSize}}>                            
                                    <Checkbox {...labelCheck} style={{color: colors.primary}} />
                                    RECO transaction
                                </Typography>
                                <Typography component="div" variant="h5" style={{fontSize: stylesMain.fontSize}}>                            
                                    <Checkbox {...labelCheck} style={{color: colors.primary}} />
                                    Security setting update
                                </Typography>
                                <Divider/>
                            </div> */}
                            <Typography component="div" variant="h5" style={{fontSize: stylesMain.fontSize, paddingTop: '20px'}}>                            
                                
                                {/* <Typography component="div" variant="h6" style={{fontSize: stylesMain.fontSize}}> 
                                    <label style={{fontSize: stylesMain.fontSize}} >{"Password           "}</label>
                                    <Field type="password" name="firstName" component="input" placeholder="password" style={{outerHeight: '70px'}} />
                                    <Button variant="contained" style={{backgroundColor: colors.primary, color: 'white', marginLeft: '50px'}}>Change</Button>
                                </Typography> */}
                                <Typography component="span" variant="h6" style={{fontSize: stylesMain.fontSize, display: 'flex', alignItems: "center"}}>  
                                    PIN:&nbsp;&nbsp;&nbsp;&nbsp;
                                    <EdiText 
                                        type="text" 
                                        hint={
                                            <span className="custom-hint">
                                              <i>Only 4 digits</i>
                                            </span>
                                        }
                                        cancelButtonContent={<strong>Cancel</strong>}

                                        editButtonContent="Edit"
                                        viewProps={{
                                            className: 'react-answer-1',
                                            style: { borderRadius: 3 }
                                        }}
                                        validationMessage="Pin should be only 4 digits"
                                        validation={val => val.length === 4}
                                        value={pin} 
                                        onSave={handleSave} 
                                        onCancel={v => setPin("****")}
                                        onEditingStart={v => setPin(currentUser?.pin_code)}
                                    />
                                </Typography>
                                <Typography component="span" variant="h6" style={{fontSize: stylesMain.fontSize, display: 'flex', alignItems: "center"}}>  
                                    <i style={styles.u}> Security question 1:</i>
                                    <EdiText type="text" value={quest1} onSave={handleSaveQuest1} />
                                </Typography>
                                <Typography component="span" variant="h6" style={{fontSize: stylesMain.fontSize, display: 'flex', alignItems: "center"}}>  
                                <i style={styles.u}>Answer to question 1:</i>
                                     <EdiText type="text" value={ans1} onSave={handleSaveAns1} />
                                </Typography>
                                <Typography component="span" variant="h6" style={{fontSize: stylesMain.fontSize, display: 'flex', alignItems: "center"}}>  
                                <i style={styles.u}>Security question 2:</i>
                                    <EdiText type="text" value={quest2} onSave={handleSaveQuest2} />
                                </Typography>
                                <Typography component="span" variant="h6" style={{fontSize: stylesMain.fontSize, display: 'flex', alignItems: "center"}}>  
                                <i style={styles.u}> Answer to question 2:</i>
                                    <EdiText type="text" value={ans2} onSave={handleSaveAns2} />
                                </Typography>
                                
                            </Typography>
                            <a href={config['auth-server-url']+"/realms/"+config['realm']+"/account"} target='_blank'>
                                Advanced setting
                            </a>
                            <br/>
                            <br/>
                            {/* <br/>
                            <a href={WEBSITE_URL+"/privacy-policy"} target="_blank" style={{color: colors.primary, cursor: "pointer"}} >
                                Privacy-policy
                            </a>
                            
                            <a href={WEBSITE_URL+"/disclaimer"} target="_blank" style={{color: colors.primary, cursor: "pointer", marginLeft: '25px'}} >
                                Disclaimer
                            </a>
                            {"     "}
                            <a href={WEBSITE_URL+"/cookies"} target="_blank"  style={{color: colors.primary, cursor: "pointer", marginLeft: '25px'}} >
                                Cookies
                            </a> 
                            <br/> */}
                            <br/>
                            <a onClick={onConfirmDeletion} style={{color: 'red', cursor: "pointer"}} >
                                Delete my account
                            </a>
                        </CardContent>
                    </Card>
                </Grid>

            </Grid>
        </Box>
        
    </div>
}

const styles={
        u: {
        paddingRight: '50px'
    }
}
export default Profile