import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import {colors} from '../../themes'
import { getUserList, changeUserStatus, countUsers } from "../../utility";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Snack from '../../components/Snack'
import CommentPopup from '../../components/CommentPopup'
import { Responsive } from "responsive-react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { useMediaQuery } from 'react-responsive'

function createData(name, username, email, id_doc, kycValidated, files, id_document_number, userID, kycSubmitted, kycValidated2) {
  return {
    name,
    username,
    email,
    id_doc,
    kycValidated,
    files, 
    id_document_number,
    userID,
    kycSubmitted,
    kycValidated2
  };
}


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Account ID',
  },
  {
    id: 'username',
    numeric: true,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'email',
    numeric: true,
    disablePadding: false,
    label: 'email',
  },
  {
    id: 'id_doc',
    numeric: true,
    disablePadding: false,
    label: 'Document Type',
  },
  {
    id: 'kycValidated',
    numeric: true,
    disablePadding: false,
    label: 'Kyc status',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'normal' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell
            align={'right'}
            padding={'normal'}
          >
            <TableSortLabel
            >
              {"Actions"}
            </TableSortLabel>
          </TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};



export function Users(props) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('username');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [numberItem, setNumberItem] = React.useState(0);

  const [typeSnack, setTypeSnack] = React.useState("success");

  const [messageSkack, setMessageSnack] = React.useState("");
  const [openSnack, setOpenSnack] = React.useState(false);
  const [difRow, setDifRowCount] = React.useState(0);

  const [kycSUbmited, setKycSubmited] = React.useState(null)
    const [users, setUsers] = React.useState([])

    async function calculTabData(page, max, kycStatus=kycSUbmited, numberUser = undefined){
      try {
        const users = await getUserList(page, max, kycStatus)
        console.log('users users users users', users)
        if(users){
            let locRow = []
            for (let index = 0; index < users.length; index++) {
                const user = users[index];
                const files = []
                if(user.id_document_1){
                    files.push(user.id_document_1.url)
                }
                if(user.id_document_2){
                    files.push(user.id_document_2.url)
                }
                if(user.id_document_selfie){
                    files.push(user.id_document_selfie.url)
                }

                locRow.push(
                    createData(
                        user.userSecure?.account_id, 
                        (user.last_name? user.last_name : "")+' '+(user.first_name? user.first_name : ""), 
                        user.email,  
                        user.id_document_type,
                        user.userSecure?.kycValidated ? "Yes" : "No",
                        files,
                        user.id_document_number?user.id_document_number: "",
                        user.objectId,
                        user.kycSubmitted,
                        user.userSecure?.kycValidated
                    )
                )
              }  
            
            // const oldNum = locRow.length
            // console.log('oldNum oldNum oldNum oldNum', oldNum, numberUser)
            // if(kycStatus === 'validated')  {
            //   locRow = locRow.filter(b => b.kycValidated2 )
            // }    
            // if(kycStatus === 'submitted' || kycStatus === 'no')  {
            //   //locRow = locRow.filter(b => !b.kycValidated2 )
            // } 
            
            // const newNum = locRow.length
            // console.log('oldNum oldNum oldNum oldNum', newNum)
            // const diff = oldNum - newNum
            // if(numberUser){
            //   setNumberItem(()=>numberUser-diff)
            // }
            setUsers(locRow)

        }
      } catch (error) {
        
      }
    }
    React.useEffect(()=>{
        (async()=>{
          console.log('started useEffect user here')
            const count = await countUsers()
            setNumberItem(count) 
            await calculTabData(0, 5)
        })()
    }, [])
  
    const isMobile = useMediaQuery({ maxWidth: 767 })

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    calculTabData(newPage, rowsPerPage)
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    calculTabData(0, parseInt(event.target.value, 10))
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - numberItem) : 0;

  const handleChangeKycStatus = async (e) => {
    setKycSubmited(e.target.value)
    const count = await countUsers(e.target.value)
    setNumberItem(count)
    calculTabData(page, rowsPerPage, e.target.value, count)
    
  }
  const onChangeStatus = async (row, comment, sendMail) => {
    (async function(){
      if(!window.confirm("Confirm "+ (row.kycValidated2 ? "Desactivation" : "Activation")+' User')) return
      const res = await changeUserStatus(row.userID, comment, sendMail)
      calculTabData(page, rowsPerPage)
      if(!res.error){
        setMessageSnack("User status change success !")
        setTypeSnack("success")
      }else{
        setMessageSnack(res.message)
        setTypeSnack("error")
      }
      setOpenSnack(true)
    })()
  }


  return (
    <Box sx={{ width: '100%' }}>
      <FormControl variant="standard" sx={{ m: 1, width: 400 }} style={{width: "300px", paddingBottom: "20px", paddingTop: "10px", alignItems: 'right'}}>
        <InputLabel id="demo-simple-select-standard-label">Kyc status</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard1"
          //value={kysStatus}
          onChange={handleChangeKycStatus}
          label="Filter" 
        >
          <MenuItem value={null}>
            <em>None</em>
          </MenuItem>
          <MenuItem value={"submitted"}>Submitted</MenuItem>
          <MenuItem value={"validated"}>Validated</MenuItem>
          <MenuItem value={"no"}>No submitted</MenuItem>
        </Select>
      </FormControl>   
      <Paper sx={{ width: '100%', mb: 2 }}>
        <Responsive displayIn={["Laptop", "Tablet"]}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={numberItem}
              />
              <TableBody>
                {//stableSort(users, getComparator(order, orderBy))
                  //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  users.filter(a =>{
                    if(kycSUbmited === "submitted"){
                      return !a.kycValidated2
                    }else if(kycSUbmited === "no"){
                      return !a.kycValidated2
                    }else if(kycSUbmited === "validated"){
                      return a.kycValidated2
                    }else{
                      return true
                    }
                  }).map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        selected={isItemSelected}
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="normal"
                          
                        >
                          {row.name}
                        </TableCell>
                        
                        <TableCell align="right">{row.username}</TableCell>
                        <TableCell align="right">{row.email}</TableCell>
                        <TableCell align="right">
                            {row.id_doc} <br/>
                            {row.id_document_number !== "" ? "Number: "+row.id_document_number : ""}
                            {row.files.map((f, i)=>
                              <div>
                                  <a target="_blank" href={f}>{"File "+ (i+1)}</a>
                              </div>
                            )}
                        </TableCell>
                        <TableCell align="right">
                          {row.kycValidated2 ?
                          "Validated" : row.kycSubmitted? 'Submitted' : "No submitted"
                          }
                          </TableCell>

                        <TableCell align="right">
                        <CommentPopup 
                          label={row.kycValidated2 ? "Desactivate" : "Activate"} 
                          onChangeStatus={onChangeStatus}
                          row={row}
                          textarea={"Add comment before "+(row.kycValidated2 ? "Desactivate" : "Activate")+ " user"}
                        />

                          {/* <Button 
                                variant="contained" 
                                style={{backgroundColor: colors.primary, color: 'white', ...styles.customButton}}
                                onClick={()=>{
                                    (async function(){
                                      if(!window.confirm("Confirm "+ (row.kycValidated2 ? "Desactivation" : "Activation")+' User')) return
                                      const res = await changeUserStatus(row.userID)
                                      calculTabData(page, rowsPerPage)
                                      if(!res.error){
                                        setMessageSnack("User status change success !")
                                        setTypeSnack("success")
                                      }else{
                                        setMessageSnack(res.message)
                                        setTypeSnack("error")
                                      }
                                      setOpenSnack(true)
                                    })()
                                    
                                  }
                                }
                          >
                              {row.kycValidated2 ? "Desactivate" : "Activate"}
                          </Button> */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Responsive>

        {
          //Mobile view started here ********
        }

        <Responsive displayIn={["Mobile"]}>
          {users.filter(a =>{
              if(kycSUbmited === "submitted"){
                return !a.kycValidated2
              }else if(kycSUbmited === "no"){
                return !a.kycValidated2
              }else if(kycSUbmited === "validated"){
                return a.kycValidated2
              }else{
                return true
              }
            }).map((row, index) => {
              return (
                <CardResponsiveContract
                  row={row}
                  key={index}
                  cle={index}
                  calculTabData={calculTabData}
                  page={page} 
                  rowsPerPage={rowsPerPage}
                  setTypeSnack={setTypeSnack}
                  setMessageSnack={setMessageSnack}
                  setOpenSnack={setOpenSnack}
                  onChangeStatus={onChangeStatus}
                />
              );
            })}
          
        </Responsive>


        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={numberItem}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={isMobile? "Rows:" : "Rows per page:"}
        />
      </Paper>
      <Snack open={openSnack} message={messageSkack} muteSnack={setOpenSnack} type={typeSnack}/>
    </Box>
  );
}


function CardResponsiveContract({row, onChangeStatus}){
  return(
    <>
      <br/>
      <Card sx={{ minWidth: 275 }} style={{borderColor: 'red'}}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color={colors.primary} gutterBottom>
            {row.name}
          </Typography>
          <Typography variant="body2">
            Name:{" "}{row.username}
          </Typography>
          <Typography variant="body2">
           Email:{" "}{row.email}
          </Typography>

          <Typography variant="body2">
          <i> Document:</i>{" "}
              {row.id_doc} <br/>
            <span style={{marginLeft: "20px"}}>
              {row.id_document_number !== "" ? "Number: "+row.id_document_number : ""}
              {row.files.map((f, i)=>
                <div style={{marginLeft: "20px"}} key={i}>
                    <a target="_blank" href={f}>{"File "+ (i+1)}</a>
                </div>
              )}
            </span>
          </Typography>
          <Typography variant="body2">
          <i> Kyc Status:</i>{" "}
          {row.kycValidated2 ? "Validated" : row.kycSubmitted? 'Submitted' : "No submitted"}
          </Typography>
        </CardContent>
        <CardActions>
          <CommentPopup 
            label={row.kycValidated2 ? "Desactivate" : "Activate"} 
            onChangeStatus={onChangeStatus}
            row={row}
            textarea={"Add comment before "+(row.kycValidated2 ? "Desactivate" : "Activate")+ " user"}
          />
        {/* <Button 
          style={{color: colors.primary}}
          onClick={()=>{
              (async function(){
                if(!window.confirm("Confirm "+ (row.kycValidated2 ? "Desactivation" : "Activation")+' User')) return
                const res = await changeUserStatus(row.userID)
                calculTabData(page, rowsPerPage)
                if(!res.error){
                  setMessageSnack("User status change success !")
                  setTypeSnack("success")
                }else{
                  setMessageSnack(res.message)
                  setTypeSnack("error")
                }
                setOpenSnack(true)
              })()
              
            }
          }
        >
            {row.kycValidated2 ? "Desactivate" : "Activate"}
        </Button> */}
        </CardActions>
      </Card>
    </>
  )
}

const styles = {
  card: {
      backgroundColor: colors.secondary, 
      textAlign: 'center',
      // width: "200px"
      
  },
  button: {
      textDecoration: 'none'
  },
  customButton: {
    fontSize: "12px",
      height: "40px",
      width: "80%"
  }
}

